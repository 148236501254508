@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face{
    font-family: 'Syne';
    src: url('./Syne-VariableFont_wght.ttf');
}
@font-face{
    font-family: 'Barlow';
    src: url('./Barlow-Regular.ttf');
}
*{
    font-family:  'Syne', sans-serif;
    scroll-behavior: smooth;
}