#hero{
    background: #0A0F21;
    
    height: 87vh;
    position: relative;
}
#hero-box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin-left:  7vw;
    padding-top: 20vh;
    padding-bottom: 35vh;
    z-index: 2;
    
}
#hero-box h1{
    margin: 0;
    padding: 0;
    z-index: 2;
    white-space: nowrap;
}
#hero-title1{
    font-family: 'Syne';
    font-style: normal;
    font-weight: 700;
    font-size: 100px;
    color: #ECECEC;
    padding: 0;
    
   
}
#hero-title2{
    font-family: 'Syne';
    font-style: normal;
    font-weight: 800;
    font-size: 140px;
    line-height: 168px;
    color: #ECECEC;
    text-transform: uppercase;
    padding: 0;
    
}
#hero-desc{
   
    font-family: 'Barlow', sans-serif;
    font-style: italic;
    font-weight: 300;
    font-size: 32px;
    line-height: 38px;
    
    color: #FFFFFF;
}
.twitter{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 39px;
    gap: 10px;
    background: none;
    border-radius: 4px;
    width: 240px;
    height: 67px;
    border: 3px solid rgba(191, 161, 176, 1);

}
.twitter h2{
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 150.5%;
    background: radial-gradient(136.09% 180.79% at 48.82% 20.19%, #FEFEFE 0%, #BFA3B1 81.25%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;

}
.twitter img{
    height: 25px;
}
.twitter a{
    text-decoration: none;
}
#hero-buttons{
    display: flex;
    gap:17px;
}
#hero-buttons a{
    text-decoration: none;
}
#hero-buttons a :hover{
    cursor: pointer;
}
#hero-veronica{
    position: absolute;
    width: 40rem;
    height: auto;
    right: 0;
    bottom: 0;
    z-index: 3;
    overflow: hidden;
   
}
#gradient{
    position: relative;
    
    bottom: 700px;
    left: 70vw;
    width: 60rem;
    height: 60rem;
    border-radius: 55rem;
    z-index: 1;
    filter:blur(60px);
}
#hero-chat{
    position: absolute;
    right: 25vw;
    
    bottom: 4vw;
    font-family: 'Barlow', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 123%;
    text-align: center;
    letter-spacing: 0.015em;
    color: #FFFFFF;
    z-index: 4;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.13) 92.37%, rgba(255, 255, 255, 0) 98.52%);
    border-radius: 16px;
    padding: 24px;
}
@media screen  and (max-width: 1700px){
    #hero{
        
        height: auto;
        position: relative;
    }
    #hero-box{
        padding-top: 10vh;
        justify-content: center;
        align-items: center;
        margin-left:  0;
        z-index: 3;
        padding-bottom: 50vw;
    }
    #hero-title1{
        font-size: 5vw;
        line-height: normal;
    }
    #hero-title2{
        font-size: 9vw;
        line-height: normal;
    }
    #hero-desc{
        font-size: 3vw;
        text-align: center;
        padding: 0 6vw 0 6vw;
        line-height: normal;
    }
    #hero-buttons{
        z-index: 4;
    }
    #hero-veronica{
        all:initial;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width:50vw;
        z-index: 2;
    }
    #gradient{
        all:initial;
        position: absolute;
        overflow: hidden;
        bottom: -40vw;
        left: 50%;
        transform: translateX(-50%);
        width: 100vw;
        height: 100vw;
        border-radius: 55rem;
        z-index: 1;
        filter:blur(60px);
    }
    #hero-chat{
        display: none;
    }
}
@media screen and (max-width: 800px){
    #hero-box{
        padding-top: 4vw;
        
    }
    .join-waitlist{
        padding: 16px 39px;
        gap: 10px;
        width: 40vw;
        height: 13vw;
        
        background: linear-gradient(180deg, #FFFFFF 0%, #BD9FAE 100%);
        border-radius: 4px;
        border:none;
       
    }
    
    .twitter{
        width: 40vw;
        height: 13vw;
        
    }
    .twitter h2{
        font-size: 4vw;
    }
    #nav-join{
         margin-right: 3vw;
    }
    .join-waitlist h2{
        
        font-weight: 800;
        font-size: 4vw;
         background: radial-gradient(136.09% 180.79% at 48.82% 20.19%, #051856 0%, #000000 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
       
    }
    #hero-twitter img{
        width: 5vw;

    }
}